var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.teamInfo
      ? _c(
          "div",
          { staticClass: "dashboard-container" },
          [
            _c("Sidebar", { attrs: { tabs: _vm.visibleTabs } }),
            _c("div", [_c("v-content", [_c("router-view")], 1)], 1),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }