<template>
  <div>
    <div class="nav-wrapper">Hey</div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
.nav-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  height: 64px;
  margin-left: 200px;
}
</style>