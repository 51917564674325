<template>
  <v-navigation-drawer app width="200" class="side-bar-bg">
    <v-layout align-center justify-space-around></v-layout>
    <div class="text-center team-name">
      <div class="team-name-div" v-if="teamBio.team_name">
        <div class="name-short">
          {{ abbrevateName(teamBio.team_name).toUpperCase() }}
        </div>
        <div class="team-username">{{ truncate(teamBio.team_name, 10) }}</div>
      </div>
      <div v-else class="team-name-div">
       <!--  <div class="team-username">No Team</div> -->
        <div class="team-username">{{  truncate(user.name, 16) }}</div>

        
      </div>
    </div>
    <div class="sb-divider"></div>
    <div id="tab-section" class="wrap_tab">
      <div class="single-tab" v-for="(tab, index) in tabs" :key="tab.icon">
        <router-link
          exact
          :key="index"
          :to="{ name: tab.route_name }"
          tag="div"
          class="sb-tab-menu c-pointer"
          active-class="selected-tab"
        >
          <div class="tab-cover">
            <span class="opc"><img :src="tab.icon" /></span>
            <span class="tab-name">{{ tab.name }}</span>
          </div>
        </router-link>
      </div>
      <div class="single-tab bottom-tab">
        <router-link
          exact
          :to="{ name: `dev-referral` }"
          tag="div"
          class="sb-tab-menu c-pointer"
          active-class="selected-tab"
        >
          <div class="tab-cover menu-wrapper">
            <span class="opc">
              <img src="/img/dashboard/client-icons/referral.svg" />
            </span>
            <span class="tab-name" style="padding-top: 4px">
              Refer a friend
            </span>
          </div>
        </router-link>

        <router-link
          exact
          tag="div"
          :to="{ name: 'dev_profile' }"
          class="sb-tab-menu c-pointer"
          active-class="selected-tab"
        >
          <div class="tab-cover menu-wrapper" @click.capture="handleDropdown">
            <LogoutMenu
              v-if="isViewProfile"
              v-bind="{ user, truncate, closeLogoutMenu }"
            />
            <v-avatar
              :size="32"
              color="grey lighten-4"
              class="profile_bg"
              :style="{
                backgroundImage: `url(${
                  $imageUrl + user.profile_picture || imageAvatar
                })`,
              }"
            ></v-avatar>
            <span class="tab-name" style="margin-left: 8px">{{
              truncate(user.name, 16)
            }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </v-navigation-drawer>
</template>



<script>
import shortName from "@/mixins/shortName";
import truncate from "@/mixins/truncate";
import { mapState, mapActions } from "vuex";
import LogoutMenu from "@/components/Popups/logoutMenu";
export default {
  components: {
    LogoutMenu,
  },
  props: ["tabs"],
  mixins: [shortName, truncate],
  data() {
    return {
      imageAvatar: "/img/onboarding/profile-avatar.svg",
      isViewProfile: false,
    };
  },
  mounted() {},
  methods: {
    ...mapActions("modalMgmt", ["toogleViewProfile"]),

    handleDropdown(e) {
      e.preventDefault();
      // this.toogleViewProfile(true);
      this.isViewProfile = true;
    },
    closeLogoutMenu() {
      this.isViewProfile = false;
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    // ...mapState("modalMgmt", ["isViewProfile"]),
    teamBio() {
      return this.teamInfo.team;
    },
    user() {
      return this.teamInfo.user;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat, sans-serif;
}
.side-bar-bg {
  background: #1b1e22;
  width: 200px;
}
.selected-tab > img {
  filter: invert(34%) sepia(55%) saturate(7080%) hue-rotate(208deg)
    brightness(95%) contrast(104%) !important;
}
.selected-tab > div > span > img {
  filter: invert(1) brightness(100) !important;
}
.selected-tab > div > span.opc {
  opacity: 1;
}
.sb_active_tab > div > span.sb-text {
  opacity: unset;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #ffffff;
}
.opc {
  opacity: 0.7;
  margin-right: 12px;
}
.sb-text {
  color: #979da5;
}
.wrap_tab {
  position: relative;
  height: calc(100vh - 88px);
}
.bottom_tab {
  position: absolute;
  bottom: 109px;
  left: 0px;
  right: 0px;
}

.sb-navigation-drawer {
  background-color: #212121;
  width: 200px;
  margin: 0;
  padding: 0;
}

.sb-divider {
  border-top: 1px solid #383e47;
  margin: 5px 0px;
}
.sb-text {
  font-style: Medium;
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
  margin-bottom: 5px;
  text-align: center;
  color: #cdcdcd;
  font-weight: 500;
  /* opacity: 0.65; */
}
.sb-text-active {
  color: #212121;
  font-weight: 700;
  opacity: 1;
}

/* New Styles */
.team-name-div {
  display: flex;
}
.team-name {
  margin: 16px 24px;
  display: flex;
}
.name-short {
  background: #ffffff;
  border-radius: 4px;
  padding: 5px 6px;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #1b1e22;
}
.team-username {
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  margin-left: 8px;
  margin-top: 5px;
}
.wrap_tab {
  margin-top: 24px;
}
.single-tab {
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 8px;
}
.tab-cover {
  padding: 12px;
  text-align: initial;
}
.selected-tab {
  background: #131416;
  border-radius: 4px;
}
.selected-tab span {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #ffffff;
}
.tab-name {
  font-weight: normal;
  color: #979da5;
  font-size: 14px;
  line-height: 130%;
}
.c-pointer {
  cursor: pointer;
}
.bottom-tab {
  position: absolute;
  bottom: 54px;
  left: 0px;
  right: 0px;
}
.menu-wrapper {
  position: relative;
}
</style>




