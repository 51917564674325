<template>
  <div>
    <div v-if="teamInfo" class="dashboard-container">
      <Sidebar v-bind:tabs="visibleTabs" />
      <div>
        <!-- <Toolbar /> -->
        <v-content>
          <router-view></router-view>
        </v-content>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Sidebar from "./sidebar";
import Toolbar from "./toolbar";
import { getDev } from "@/api";
import { logoutUser } from "@/utils/logoutUser";

export default {
  components: {
    Sidebar,
    Toolbar,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "Overview",
          icon: "/img/dashboard/icons/overview.svg",
          active: true,
          route_name: "dev_dashboard",
          onlyForActivatedTeam: false,
        },
        {
          id: 2,
          name: "Products",
          icon: "/img/dashboard/icons/products.svg",
          active: false,
          route_name: "dev_projects",
          onlyForActivatedTeam: true,
        },
        {
          id: 3,
          name: "Wallet",
          icon: "/img/dashboard/icons/wallet.svg",
          active: false,
          route_name: "dev_wallet",
          onlyForActivatedTeam: true,
        },
        {
          id: 4,
          name: "Team",
          icon: "/img/dashboard/icons/team.svg",
          active: false,
          route_name: "team",
          onlyForActivatedTeam: false,
        },
        // {
        //   id: 5,
        //   name: "Support",
        //   icon: "/img/dashboard/icons/support.svg",
        //   active: false,
        //   route_name: "dev_help",
        // },
      ],
    };
  },
  created() {
    this.fetchUser();
  },
  mounted() {},
  methods: {
    ...mapActions("devDashboard", ["setTeamDetails"]),

    async fetchUser() {
      await getDev()
        .then((res) => {
          if (res.data) {
            this.$developConsole(res.data, "Team data fetched...");
            this.setTeamDetails(res.data);
          }
        })
        .catch((err) => {
          this.$developConsole(err);
          logoutUser();
        });
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    teamActivated() {
      return (
        this.teamInfo.user.activation_level >= 2 &&
        this.teamInfo.team.activation_level == 8
      );
    },
    visibleTabs() {
      return this.tabs.filter(
        (t) => this.teamActivated || !t.onlyForActivatedTeam
      );
    },
  },
};
</script>
<style scoped>
</style>